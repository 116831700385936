@import '../../../UI/global.module.scss';

.container {
	width: 100%;
	margin: 0 auto;
  padding: 72px 56px 74px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 80px;
	background-color: $dark-green;

	.header {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.title {
			font-size: 48px;
			font-family: Roca One;
			font-weight: 400;
			line-height: 55px;
			letter-spacing: 1.2px;
			text-align: center;
			color: #FCFFFE;
		}
	}


	.body {
		position: relative;
		max-width: 1328px;
		border-radius: 40px;

		background-color: #FCFFFE;
		padding: 40px 72px 68px 118px;
		display: flex;
		align-items: center;

		.containerCards {
			display: flex;
			flex-direction: column;
			width: 385px;
			gap: 24px;
			padding: 0 70px 0 0;
			border-right: 2px solid #B9BFBD;

			.thumbnail {
				overflow: hidden;
				border-radius: 20px;
				box-sizing: border-box;
				cursor: pointer;
			}
		}

		.player {
			padding: 0 0 0 64px;
		}

		.icon {
			position: absolute;
			left: calc(100% - 170px);
			bottom: -85px;
		}
	}

	.button {
		width: 420px;
		height: 54px;
	}
}

.mainColor {
	color: $main-color;
}

.secondaryColor {
	color: $secondary-color;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 768px) {
	.container {
		padding: 40px 20px;
		gap: 32px;

		.header {
	
			.title {
				font-size: 30px;
				line-height: 110%;
				letter-spacing: 0.6px;
				text-align: center;
				color: #FCFFFE;
			}
		}
		
		.body {
			max-width: 100%;
			flex-direction: column-reverse;
			padding: 24px 0 32px;
			align-items: flex-start;
			overflow: hidden;
			border-radius: 12px;

			.containerCards {
				display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        overflow-x: scroll;
        width: 100%;
        padding: 24px 0 0 0;
				border-right: none;

				.thumbnail {
					border-radius: 12px;
					width: 230px;

					& img {
						height: auto;
						width: 100%;
					}
				}
			}

			.player {
				padding: 24px 0 24px;
				width: calc(100% - 40px);
				margin: 0 20px;
				border-bottom: 2px solid #B9BFBD;
			}
		}

		.button {
			width: 100%;
			height: 48px;
		}
	}

	.mobile {
		display: block;
	}
}